import React, { useState, useEffect } from "react";
import AmlIdentityService from "../services/AmlIdentityService";

const amlIdentityService = new AmlIdentityService();

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const PersonaIndTrial = () => {
  const [formData, setFormData] = useState({
    birthdate: '1994-04-12',
    firstName: '',
    middleName: 'Fred',
    lastName: '',
    telephone: '212-555-1212',
    addressStreet1: '123 Main St',
    addressStreet2: 'Apt. 4',
    addressCity: 'San Francisco',
    addressState: 'California',
    addressZip: '23453',
    ssn: '050-34-7865',
    emailAddress: 'scott-fake@opalvest.com',
    countryCode: 'US',
    crdIndividual: '123456',
    crdFirm: '9876',
    refId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submit action
    console.log(`First Name: ${formData.firstName}, Last Name: ${formData.lastName}, Telephone: ${formData.telephone}`);
    const result = await amlIdentityService.sendIndividual(formData);
    console.log('refRes', result);
    const { "reference-id": resReferenceId } = result;
    console.log('referenceId', resReferenceId);

    for (let i = 0; i < 6; i++) {
      console.log('i', i);

      const info = await amlIdentityService.getInfo(resReferenceId);
      console.log('info', info);
      if (info.data === 'created') {
        console.log('Transaction has been created.');
      } else if (info.data === 'approved') {
        console.log('Transaction has been approved: you can proceed with the user.');
        break;
      } else if (info.data === 'declined') {
        console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
        break;
      } else if (info.data === 'needs_review') {
        console.log('Transaction is awaiting manual review (there was a report hit)');
        break;
      } else if (info.data === 'errored') {
        console.log('Transaction has errored.');
        break;
      }

      await delay(5000);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="middleName">Middle Name:</label>
        <input
          type="text"
          id="middleName"
          name="middleName"
          value={formData.middleName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="telephone">Telephone:</label>
        <input
          type="tel"
          id="telephone"
          name="telephone"
          value={formData.telephone}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="birthdate">Birth Date YYYY-MM-DD:</label>
        <input
          type="text"
          id="birthdate"
          name="birthdate"
          value={formData.birthdate}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="addressStreet1">Street 1:</label>
        <input
          type="text"
          id="addressStreet1"
          name="addressStreet1"
          value={formData.addressStreet1}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="addressStreet2">Street 2:</label>
        <input
          type="text"
          id="addressStreet2"
          name="addressStreet2"
          value={formData.addressStreet2}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="addressCity">City:</label>
        <input
          type="text"
          id="addressCity"
          name="addressCity"
          value={formData.addressCity}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="addressState">State:</label>
        <input
          type="text"
          id="addressState"
          name="addressState"
          value={formData.addressState}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="addressZip">Zip:</label>
        <input
          type="text"
          id="addressZip"
          name="addressZip"
          value={formData.addressZip}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="ssn">SSN:</label>
        <input
          type="text"
          id="ssn"
          name="ssn"
          value={formData.ssn}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="emailAddress">Email Address:</label>
        <input
          type="text"
          id="emailAddress"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="countryCode">Country Code:</label>
        <input
          type="text"
          id="countryCode"
          name="countryCode"
          value={formData.countryCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="crdIndividual">Individual CRD:</label>
        <input
          type="text"
          id="crdIndividual"
          name="crdIndividual"
          value={formData.crdIndividual}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="crdFirm">Firm CRD:</label>
        <input
          type="text"
          id="crdFirm"
          name="crdFirm"
          value={formData.crdFirm}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="refId">Reference ID:</label>
        <input
          type="text"
          id="refId"
          name="refId"
          value={formData.refId}
          onChange={handleChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default PersonaIndTrial;
